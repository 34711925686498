import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import Image from 'next/image';
import { useRouter } from 'next/router';
import Link from 'next/link';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import { ArrowLeftIcon, MenuIcon } from '@heroicons/react/solid';
import {
  // BadgeCheckIcon,
  LightningBoltIcon,
  XIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  // ArrowCircleUpIcon,
  BellIcon,
  ViewListIcon,
} from '@heroicons/react/outline';
import Div100vh from 'react-div-100vh';

import logoImage from '@assets/images/exodia.png';
import logoVerticalImage from '@assets/images/exodia-vertical.png';
import LogoLight from '@assets/images/exodia-light.svg';
import LogoLightVertical from '@assets/images/exodia-vertical-light.svg';

import GridIcon from '@assets/icons/grid.svg';
import OfficeBagIcon from '@assets/icons/office-bag.svg';

// import LeaderboardIcon from '@assets/icons/leaderboard.svg';
import FireIcon from '@assets/icons/fire.svg';
import SniperIcon from '@assets/icons/crosshair.svg';
import GrowthGraphIcon from '@assets/icons/growth-graph.svg';
// import ChromeIcon from '@assets/icons/chrome.svg';
// import UsaIcon from '@assets/icons/united-states.svg';
// import EthIcon from '@assets/icons/eth.svg';

import { MetaMaskConnect } from './MetaMaskConnect';
import { ThemeChanger } from './ThemeChanger';
// import { Select, SelectOption } from '@components/Select';
// import { ETH_SYMBOL } from '@utils/price';
import { Button } from '@components/Button';
import { useCustomTheme } from '@utils/theme';
import { useMenuContext } from '@utils/MenuContext';

export const COLLECTION_BASE_URL = '/collection';
export const WALLET_BASE_URL = '/wallet';

const menuItems = [
  {
    label: 'Dashboard',
    href: '/dashboard',
    icon: GridIcon,
  },
  // {
  //   label: 'Leaderboard',
  //   href: '/leaderboard',
  //   icon: LeaderboardIcon,
  // },
  // {
  //   label: 'Drops',
  //   href: '/drops',
  //   icon: FireIcon,
  // },
  {
    label: 'Mints',
    href: '/mints',
    icon: LightningBoltIcon,
    isHeroIcon: true,
  },
  {
    label: 'Sniper',
    href: '/events/live',
    icon: SniperIcon,
    // subsections: [
    //   {
    //     label: 'All Sales',
    //     href: '/events/live',
    //   },
    //   {
    //     label: 'Mints',
    //     href: '/events/mints',
    //   },
    //   {
    //     label: 'Profit',
    //     href: '/events/profit',
    //   },
    //   {
    //     label: 'Listing',
    //     href: '/events/listing',
    //   },
    // ],
  },
  {
    label: 'Collections',
    href: '/collection',
    icon: ViewListIcon,
  },
  // {
  //   label: 'Trending',
  //   href: '/trending',
  //   icon: GrowthGraphIcon,
  // },
  // {
  //   label: 'Rarity',
  //   href: '/rarity',
  //   icon: SparklesIcon,
  //   isHeroIcon: true,
  // },
  {
    label: 'Wallet',
    href: '/wallet',
    icon: OfficeBagIcon,
  },
  {
    label: 'Alerts',
    href: '/alerts',
    icon: BellIcon,
  },
  // {
  //   label: 'Blue Chip Index',
  //   href: '/exodia-10',
  //   icon: GrowthGraphIcon,
  // },
  // {
  //   label: 'Historical Index',
  //   href: '/historical',
  //   icon: GrowthGraphIcon,
  // },
  {
    label: 'Indexes',
    hrefs: ['/exodia-10', '/historical', '/anime'],
    icon: GrowthGraphIcon,
    // subsections: [
    //   {
    //     label: 'Blue Chip',
    //     href: '/exodia-10',
    //   },
    //   {
    //     label: 'Historical',
    //     href: '/historical',
    //   },
    //   {
    //     label: 'Anime',
    //     href: '/anime',
    //   },
    // ],
  },
  // {
  //   label: 'Chrome Extension',
  //   href: '/chrome-extension',
  //   icon: ChromeIcon,
  // },
  // {
  //   label: 'Alpha',
  //   href: '/alpha',
  //   // TODO add Icon to designs, remove isHeroIcon
  //   icon: ArrowCircleUpIcon,
  //   isHeroIcon: true,
  // },
  // {
  //   label: 'Exodia Elite NFT',
  //   href: '/exodia-elite-nft',
  //   // TODO add Icon to designs, remove isHeroIcon
  //   icon: BadgeCheckIcon,
  //   isHeroIcon: true,
  // },
];

// const currencies: SelectOption<string>[] = [
//   { name: '$ USD', value: 'usd', icon: UsaIcon },
//   { name: `${ETH_SYMBOL} ETH`, value: 'eth', icon: EthIcon },
// ];

interface SideMenuBlockProps {
  isOpen: boolean;
  setAllIsOpen: (isOpen: boolean) => void;
}

const SideMenuBlock: React.FC<SideMenuBlockProps> = props => {
  const { isOpen, setAllIsOpen } = props;
  const router = useRouter();
  const { isDark } = useCustomTheme();
  const size = useWindowSize();
  const menuItemsRef = useRef<HTMLDivElement>(null);

  const toggleIsOpen = useCallback(() => {
    setAllIsOpen(!isOpen);
  }, [isOpen, setAllIsOpen]);

  const [displayScroll, setDisplayScroll] = useState({ top: false, bottom: false });

  const showTopScrollButton = !isOpen && displayScroll.top;
  const showBottomScrollButton = !isOpen && displayScroll.bottom;

  const updateScrollButtonState = useCallback(() => {
    if (menuItemsRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = menuItemsRef.current;

      const showTopScroll = scrollTop > 1;
      const showBottomScroll = scrollTop < scrollHeight - clientHeight - 1;

      if (showTopScroll !== displayScroll.top || showBottomScroll !== displayScroll.bottom) {
        setDisplayScroll({ top: showTopScroll, bottom: showBottomScroll });
      }
    }
  }, [displayScroll.bottom, displayScroll.top]);

  const scrollBy = (value: number) => {
    if (menuItemsRef.current) {
      const { scrollTop, clientHeight } = menuItemsRef.current as HTMLElement;
      const newTopPosition =
        value > 0 ? Math.min(scrollTop + value, clientHeight) : Math.max(scrollTop + value, 0);

      menuItemsRef.current.scrollTo({
        top: newTopPosition,
        behavior: 'smooth',
      });
    }
  };

  const scrollToBottom = useCallback(() => {
    scrollBy(100);
  }, []);

  const scrollToTop = useCallback(() => {
    scrollBy(-100);
  }, []);

  useEffect(() => {
    updateScrollButtonState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, size]);

  return (
    <>
      <div
        className={classNames('fixed inset-0 transition-opacity z-40 sm:hidden', {
          '-z-30': !isOpen,
        })}
      >
        <div
          className={classNames('absolute inset-0 bg-gray-750 backdrop-blur transition-opacity ', {
            'opacity-80': isOpen,
            'opacity-0': !isOpen,
          })}
          onClick={toggleIsOpen}
        ></div>
      </div>
      <div
        className={classNames('relative sm:block flex-shrink-0', {
          'sm:w-64': isOpen,
          'hidden sm:w-20': !isOpen,
        })}
      >
        <Div100vh
          className={classNames(
            'px-4 pt-5 pb-6 flex flex-col bg-background-light-mode dark:bg-background-dark-mode fixed z-40 border-r border-black dark:border-white border-opacity-15 dark:border-opacity-15',
            {
              'w-64': isOpen,
              'hidden sm:flex w-20 items-center': !isOpen,
            }
          )}
        >
          {/* TODO need svg */}
          <div className="flex items-center justify-between">
            <Link href="/" prefetch={false}>
              <a>
                {isDark ? (
                  <Image src={isOpen ? logoImage : logoVerticalImage} alt="logo" priority />
                ) : isOpen ? (
                  <LogoLight />
                ) : (
                  <LogoLightVertical />
                )}
              </a>
            </Link>
            <div className="sm:hidden">
              <Button size="xs" color="transparent" onClick={toggleIsOpen}>
                <XIcon className="h-8 w-8 text-green-light-mode" />
              </Button>
            </div>
          </div>
          <>
            {isOpen && (
              <div className="mt-8">
                <MetaMaskConnect />
              </div>
            )}

            {showTopScrollButton && (
              <div className="flex w-full justify-center mt-1 h-5">
                <Button size="xs" color="transparent-v2" onClick={scrollToTop}>
                  <ChevronUpIcon className="h-5 w-5" />
                </Button>
              </div>
            )}
            <div
              key="menu-items"
              className={classNames('px-2 h-full overflow-y-auto relative', {
                'scrollbar-hide': !isOpen,
                'pb-12': isOpen,
                'mt-8': !showTopScrollButton,
                'mt-2': showTopScrollButton,
                'mb-8': !isOpen && !showBottomScrollButton,
                'mb-2': !isOpen && showBottomScrollButton,
              })}
              ref={menuItemsRef}
              onScroll={updateScrollButtonState}
            >
              <ul
                className={classNames({
                  'flex flex-col items-center': !isOpen,
                })}
              >
                {menuItems.map((menuItem, itemIndex) => {
                  // const showSubSections = menuItem?.subsections
                  //   ?.map(section => section.href)
                  //   .includes(router.pathname);
                  const isCurrentPath =
                    menuItem.hrefs?.find((href: string) => router.asPath.indexOf(href) === 0) ||
                    (menuItem.href && router.asPath.indexOf(menuItem.href) === 0);

                  return (
                    <li className="block" key={menuItem.label}>
                      <Link
                        href={menuItem.hrefs ? menuItem.hrefs[0] : menuItem.href}
                        prefetch={false}
                      >
                        <a
                          className={classNames('flex text-lg', {
                            'text-primary-light-mode-main dark:text-neon-yellow-500': isCurrentPath,
                            'opacity-70 hover:opacity-100': !isCurrentPath,
                            'space-x-2': isOpen,
                            'mx-auto': !isOpen,
                            'mb-4': itemIndex !== menuItems.length - 1,
                          })}
                        >
                          <div
                            className={classNames('w-6 flex', {
                              'justify-start': isOpen,
                              'justify-center': !isOpen,
                              'mb-2': !isOpen && itemIndex !== menuItems.length - 1,
                              'h-5': !menuItem.isHeroIcon,
                              'h-6 -mx-px': menuItem.isHeroIcon,
                            })}
                          >
                            <menuItem.icon />
                          </div>
                          {isOpen && <span className="font-grifter">{menuItem.label}</span>}
                        </a>
                      </Link>

                      {/* {showSubSections && isOpen && (
                        <ul>
                          {(menuItem.subsections || []).map(subsection => {
                            const isSubsectionSelected = subsection.href === router.pathname;
                            const { contractAddressOrSlug } = router.query;
                            return (
                              <li className="block" key={subsection.label}>
                                <Link
                                  href={{
                                    pathname: subsection.href,
                                  }}
                                  prefetch={false}
                                >
                                  <a
                                    className={classNames('flex space-x-2 text-lg mb-2', {
                                      'text-primary-light-mode-main dark:text-neon-yellow-500 pointer-events-none cursor-default':
                                        isSubsectionSelected,
                                      'opacity-70 hover:opacity-100': !isSubsectionSelected,
                                    })}
                                  >
                                    {isOpen && (
                                      <span className="font-grifter text-sm ml-6">
                                        {subsection.label}
                                      </span>
                                    )}
                                  </a>
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      )} */}
                    </li>
                  );
                })}
              </ul>
            </div>
            {showBottomScrollButton && (
              <div className="flex w-full justify-center mb-1 h-5">
                <Button size="xs" color="transparent-v2" onClick={scrollToBottom}>
                  <ChevronDownIcon className="h-5 w-5" />
                </Button>
              </div>
            )}
          </>
          <div key="menu-footer">
            {/* {isOpen && (
              <div className="border border-neon-blue-700 dark:border-neon-blue-500 border-opacity-20 rounded">
                <Select<string>
                  list={currencies}
                  onChange={currency => {
                    setSelectedCurrency(currency);
                  }}
                  value={selectedCurrency}
                  toTop
                  hideBorder
                />
              </div>
            )} */}
            <div className="relative">
              {isOpen && (
                <div className="absolute inset-x-0 -top-16 h-16 bg-gradient-fade-white dark:bg-gradient-fade-black" />
              )}
              <div className="flex justify-between">
                <div>
                  <div className="hidden sm:block">
                    <IconButton color="primary" rounded isActive onClick={toggleIsOpen}>
                      <ArrowLeftIcon
                        className={classNames('h-6', {
                          'rotate-180 transistion-transform': !isOpen,
                        })}
                      />
                    </IconButton>
                  </div>
                </div>
                {isOpen && <ThemeChanger />}
              </div>
            </div>
          </div>
        </Div100vh>
      </div>
      {!isOpen && (
        <div className="fixed top-5 right-5 rounded sm:hidden bg-background-light-mode dark:bg-background-dark-mode z-40">
          <Button size="xs" color="transparent" onClick={toggleIsOpen}>
            <MenuIcon className="h-8 w-8 text-green-light-mode" />
          </Button>
        </div>
      )}
    </>
  );
};

interface SideMenuProps {}

export const SideMenu: React.FC<SideMenuProps> = props => {
  const {
    desktop: [isOpenForDesktop, setIsOpenForDesktop],
    mobile: [isOpenForMobile, setIsOpenForMobile],
  } = useMenuContext();

  const setAllIsOpen = useCallback(
    isOpen => {
      setIsOpenForMobile(isOpen);
      setIsOpenForDesktop(isOpen);
    },
    [setIsOpenForDesktop, setIsOpenForMobile]
  );

  useEffect(() => {
    // hack to redraw react-grid-layout after isOpen changes
    const resizeEvent = new Event('resize');
    window.dispatchEvent(resizeEvent);
  }, [isOpenForMobile, isOpenForDesktop]);

  return (
    <>
      <div className="hidden sm:block">
        <SideMenuBlock setAllIsOpen={setAllIsOpen} isOpen={!!isOpenForDesktop} />
      </div>
      <div className="block sm:hidden">
        <SideMenuBlock setAllIsOpen={setAllIsOpen} isOpen={!!isOpenForMobile} />
      </div>
    </>
  );
};
